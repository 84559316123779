@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 100 thin;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400 normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700 bold;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
